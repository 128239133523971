<template>
    <div>
        <Breadcrumb nombreActual="Material de Interés"></Breadcrumb>
        <section class="ficha">
            <div class="container2">
                <div class="row justify-content-center">
                    <!-- v-for material in materiales -->
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Documento Metodológico Ciclo de Aprendizaje
                            </div>
                            <div class="card-r-text">
                                Documento que describe el sistema de diseño, monitoreo y evaluación de los programas e instrumentos en materias de Ciencia, Tecnología, Conocimiento e Innovación que implementa la Oficina de Estudios y Estadísticas del Ministerio de Ciencia.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="https://api.observa.minciencia.gob.cl/api/datosabiertos/download/?handle=123456789/191247&filename=Documento%20Metodologico%20del%20Ciclo%20de%20Aprendizaje%20-%202022.pdf" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Manual de Uso de Intranet
                            </div>
                            <div class="card-r-text">
                                Documento que describe los diferentes espacios de trabajo de la plataforma diseñada para implementar el Ciclo de Aprendizaje para la evaluación de instrumentos y prigramas en materias de Ciencia, Tecnología, Conocimiento e Innovación.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="card-r">
                            <div class="card-r-title">
                                Material
                            </div>
                            <div class="card-r-subtitle">
                                Ingresar Nombre Material
                            </div>
                            <div class="card-r-text">
                                Aquí se incluirá una breve descripción sobre el material, con algunos detalles técnicos y un breve resumen explicativo.
                            </div>
                            <div class="card-r-btn">
                                <a class="btn btn-primary" href="" target="_blank"><font-awesome-icon :icon="['fas', 'download']" /> Descargar</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<ul>
                    <li>
                        Material 1: <a href="">URL</a>
                    </li>
                    <li>
                        Material 2: <a href="">URL</a>
                    </li>
                    <li>
                        Material 3: <a href="">URL</a>
                    </li>
                </ul>-->
            </div>
        </section>
    </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    name: 'Reportes',
    components: {
        Breadcrumb
    },
    async beforeCreate () {

    },
    data () {
        return {
        }
    },
    methods: {
    },
    mounted () {
    },
    computed: {
    }
}
</script>
