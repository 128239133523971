<template>
    <div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
        <Breadcrumb nombreActual="Monitoreos"></Breadcrumb>
        <section class="ficha">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="card card--section">
                            <div class="card-header">
                                <h2 class="card-title d-inline-block">Monitoreos</h2>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        Filtrar por:
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        <v-select :options="listAgencia" v-model="formSearch.agencia" label="nombre" :reduce="nombre => nombre.id" placeholder="Agencia" class="mb-2"></v-select>
                                    </div>
                                    <div class="col-md-3">
                                        <v-select :options="listSubdireccion" v-model="formSearch.subdireccion" label="nombre" :reduce="nombre => nombre.id" placeholder="Subdirección / Gerencia" class="mb-2">
                                            <span slot="no-options">Seleccione una Agencia Primero.</span>
                                        </v-select>
                                    </div>
                                    <div class="col-md-3">
                                        <v-select :options="listInstrumento" v-model="formSearch.instrumento" label="nombre" :reduce="nombre => nombre.id" placeholder="Instrumento" class="mb-2">
                                            <span slot="no-options">Seleccione una Subdirección / Gerencia Primero.</span>
                                        </v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <v-select :options="tipos" v-model="formSearch.tipo" label="tipo" placeholder="Tipo de Monitoreo" class="mb-2"></v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <v-select :options="anios" v-model="formSearch.anio" label="anio" :reduce="anio => anio.anio" placeholder="Año" class="mb-2"></v-select>
                                    </div>
                                </div>
                                <br>
                                <div class="alert alert-info" role="alert" v-if="monitoreos.length === 0">
                                        No hay información disponible
                                    </div>
                                <div class="table-responsive">
                                    <table class="table table-stripped table-hover table-bordered" id="tabla-monitoreos" v-if="monitoreos.length > 0">
                                        <thead>
                                            <tr>
                                                <td v-if="isAdmin"></td>
                                                <td>Nombre Monitoreo</td>
                                                <td>Año</td>
                                                <td>Tipo Monitoreo</td>
                                                <td>Última Actualización</td>
                                                <td>Usuario/a</td>
                                                <td>Instrumento/Agencia Asociado</td>
                                                <td>Estado</td>
                                                <td>Acción</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="monitoreo in resultQuery" v-bind:key="monitoreo.id">
                                                <td v-if="isAdmin"><font-awesome-icon v-if="!monitoreo.activo" :icon="['fas', 'ban']" style="color: red;"/><font-awesome-icon v-if="monitoreo.activo" :icon="['fas', 'circle']" style="color: #63C894;"/></td>
                                                <td>{{monitoreo.monitoreo__nombre}}</td>
                                                <td>{{monitoreo.anio}}</td>
                                                <td>{{monitoreo.monitoreo__tipo_monitoreo}}</td>
                                                <td>{{monitoreo.fecha_actualizacion | dateTimeFormat}}</td>
                                                <td>{{monitoreo.persona__nombre}}</td>
                                                <td>{{monitoreo.instrumento__nombre || monitoreo.agencia__nombre}}</td>
                                                <td><span class="badge" v-bind:class="{ 'bg-en-proceso': monitoreo.estado === 'En Proceso', 'bg-por-validar': monitoreo.estado ==='Por Validar', 'bg-validada': monitoreo.estado === 'Validada', 'bg-bloqueada': monitoreo.estado === 'Bloqueada'}">{{monitoreo.estado}}</span></td>
                                                <td>
                                                    <div class="btns mt-0">
                                                        <router-link v-if="monitoreo.monitoreo__tipo_monitoreo === 'Productos y Resultados'" :to="'/monitoreoinstrumento/' + monitoreo.monitoreo + '/' + monitoreo.instrumento + '/' + monitoreo.anio" class="btn btn-primary btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /> Editar</router-link>
                                                        <router-link v-if="monitoreo.monitoreo__tipo_monitoreo === 'Procesos'" :to="'/monitoreoagencia/' + monitoreo.monitoreo + '/' + monitoreo.agencia + '/' + monitoreo.anio" class="btn btn-primary btn-sm"><font-awesome-icon :icon="['fas', 'edit']" /> Editar</router-link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="rows"
                                :per-page="perPage"
                                aria-controls="tabla-monitoreos"
                                ></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
import resource from 'resource-axios'
import Breadcrumb from '@/components/Breadcrumb.vue'

// const MonitoreoInstrumento = resource('/monitoreo/api/listar_monitoreo_instrumentos', axios)
const MonitoreoInstrumento2 = resource('/monitoreo/api/no_pag_listar_monitoreo_instrumentos', axios)
const Anio = resource('/monitoreo/api/ver_anio_monitoreo_instrumento', axios)
const ListarAgencia = resource('/api/api/listar_agencia', axios)
const ListarSubdireccion = resource('/api/api/listar_subdireccion', axios)
const ListarInstrumento = resource('/api/api/listar_instrumento', axios)
const UsuarioActual = resource('/usuarios/api/usuario_actual', axios)

export default {
    name: 'ListarMonitoreoInstrumentos',
    components: {
        Breadcrumb
    },
    data () {
        return {
            monitoreos: [],
            currentPage: 1,
            perPage: 15,
            rows: null,
            page: null,
            anios: [],
            formSearch: { agencia: null, subdireccion: null, instrumento: null },
            listAgencia: [],
            listSubdireccion: [],
            listInstrumento: [],
            tipos: ['Productos y Resultados', 'Procesos'],
            isAdmin: false,
            show: true,
            original_length: null
        }
    },
    methods: {
        getAnio () {
            Anio.query({ }).then((res) => {
                this.anios = res.data
            })
        },
        getListAgencia () {
            ListarAgencia.query({ }).then((res) => {
                this.listAgencia = res.data
            })
        },
        getListSubdireccion () {
            ListarSubdireccion.query({ agencia: this.formSearch.agencia }).then((res) => {
                this.listSubdireccion = res.data
            })
        },
        getListInstrumento () {
            ListarInstrumento.query({ subdireccion__agencia: this.formSearch.agencia, subdireccion: this.formSearch.subdireccion }).then((res) => {
                this.listInstrumento = res.data
            })
        },
        getData () {
            MonitoreoInstrumento2.query({ instrumento: this.formSearch.instrumento, instrumento__subdireccion: this.formSearch.subdireccion, instrumento__subdireccion__agencia: this.formSearch.agencia, anio: this.formSearch.anio, monitoreo__tipo_monitoreo: this.formSearch.tipo, agencia: this.formSearch.agencia }).then((res) => {
                // this.monitoreos = res.data.items
                console.log(res.data)
                this.monitoreos = res.data
                // this.perPage = res.data.paginate.per_page
                this.rows = res.data.length
                this.original_length = res.data.length
                // this.rows = res.data.paginate.count
                this.show = false
            }, (error) => {
                this.show = false
                console.log(error)
            })
        }
    },
    mounted () {
        this.getData()
        this.getListAgencia()
        this.getAnio()
        UsuarioActual.query({}).then((res) => {
            this.usuario = res.data[0]
            if (this.usuario.tipo_usuario === 'Administrador' || this.usuario.tipo_usuario === 'Revisor Ministerio' || this.usuario.is_superuser) {
                this.isAdmin = true
            }
        })
    },
    computed: {
        resultQuery () {
            const startIndex = this.perPage * (this.currentPage - 1)
            const endIndex = startIndex + this.perPage
            return this.monitoreos.slice(startIndex, endIndex)
        }
    },
    watch: {
        'formSearch.anio': {
            deep: true,
            handler: function (newVal) {
                this.getData()
            }
        },
        'formSearch.agencia': {
            deep: true,
            handler: function (newVal) {
                if (newVal !== null) {
                    this.formSearch.subdireccion = null
                    this.getListSubdireccion()
                    this.getListInstrumento()
                } else {
                    this.listSubdireccion = []
                    this.listInstrumento = []
                    this.formSearch.subdireccion = null
                    this.formSearch.instrumento = null
                }
                this.getData()
            }
        },
        'formSearch.subdireccion': {
            deep: true,
            handler: function (newVal) {
                if (newVal !== null) {
                    this.formSearch.instrumento = null
                    this.getListInstrumento()
                } else {
                    this.listInstrumento = []
                    this.formSearch.instrumento = null
                }
                this.getData()
            }
        },
        'formSearch.instrumento': {
            deep: true,
            handler: function (newVal) {
                this.getData()
            }
        },
        'formSearch.tipo': {
            deep: true,
            handler: function (newVal) {
                this.getData()
            }
        }
    }
}
</script>
